import { delay } from '@st/utils'
import type { IconName } from '@st/ui/components/StIcon/types'
import { useActiveBetsStore } from '@st/coupon/stores/useActiveBetsStore'

export function useBetHistory() {
  const { t } = useI18n()

  const selectedHistoryType = ref('casino')

  const typeTabs = computed(() => [
    { id: 'casino', label: t('bets.casino'), icon: 'casino' as IconName },
    {
      id: 'sport',
      label: t('bets.sport'),
      icon: 'all-sports' as IconName,
    },
  ])
  const { betCounter } = storeToRefs(useActiveBetsStore())
  const selectedBetType = useRouteQuery<'inGame' | 'processed'>(
    'sportTab',
    'inGame',
  )
  const betTypes = computed(() => [
    {
      id: 'inGame',
      label: t('bets.inGame'),
      counter: betCounter.value,
    },
    { id: 'processed', label: t('bets.processed'), counter: 0 },
  ])

  const router = useRouter()
  watch(selectedHistoryType, () => {
    const { query = {} } = router.currentRoute.value
    router.replace({ query: { ...query, tab: selectedHistoryType.value } })
  })

  onMounted(() => {
    const { query } = router.currentRoute.value
    if (!query) return

    if (query?.tab === 'sport') {
      selectedHistoryType.value = 'sport'
    }

    if (query?.tab === 'casino') {
      selectedHistoryType.value = 'casino'
    }
  })

  async function scrollToTop() {
    await delay(300)

    nextTick(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    })
  }

  watch(
    () => selectedBetType.value,
    () => {
      scrollToTop()
    },
  )

  return {
    selectedHistoryType,
    selectedBetType,
    betTypes,
    typeTabs,
  }
}
