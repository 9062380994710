<template>
  <div class="casino-bet" :class="{ mobile }" data-t="casino-bet">
    <div class="game">
      <StIcon v-if="categoryIcon" :svg="categoryIcon" class="category-icon" />
      <StIcon v-else name="casino" class="category-icon" />
      <NuxtI18nLink
        class="game-info"
        :to="`/casino/games/${casinoBet.gameId}?mode=real`"
      >
        <div class="game-name" data-t="game-name-qe24">
          {{ casinoBet.gameName }}
        </div>
        <div v-if="categoryName" class="category-name">
          {{ categoryName }}
        </div>
      </NuxtI18nLink>
    </div>
    <!--  mobile off  -->
    <template v-if="!mobile">
      <div class="bet-id">
        <StCopy
          v-if="!isNullish(casinoBet?.roundId)"
          class="bet-id-copy"
          :value="casinoBet.roundId"
        >
          <span class="bet-id-text">{{ casinoBet.roundId }}</span>
        </StCopy>
      </div>
      <div class="date disable-screenshot">
        {{ relativeDate }}
      </div>
      <div class="bet-amount">
        <div v-if="isFreespin" class="crypto-amount">
          {{ freeSpinBetsNumber }}
          <img :src="freespin" width="16" alt="freespin" />
        </div>
        <div v-else class="crypto-amount">
          {{ cryptoBetsAmount }}
          <StIcon :name="currencyIcon" :size="16" />
        </div>
        <div class="fiat-amount" data-t="fiat-amount-xr3y">
          {{ fiatBetsAmount }}
        </div>
      </div>
      <div class="bet-rate" :class="betResultClass">{{ formattedRate }}</div>
    </template>
    <div v-if="isFreespin" class="bet-payoff">
      <div
        v-if="freeSpinResult === 'win'"
        class="crypto-amount"
        :class="freeSpinResultClass"
      >
        {{ freeSpinFiatSignBetResult }}
      </div>
      <template v-else>
        <div class="crypto-amount" :class="freeSpinResultClass">
          {{ freeSpinFormatedBetsNumber }}
          <img :src="freespin" width="16" alt="freespin" />
        </div>
        <div v-if="+winsAmountInGameCurrency" class="fiat-amount">
          {{ freeSpinFiatSignBetResult }}
        </div>
      </template>
    </div>
    <div v-else class="bet-payoff">
      <div class="crypto-amount" :class="betResultClass">
        {{ cryptoAmount }}
        <StIcon :name="currencyIcon" :size="16" />
      </div>
      <div class="fiat-amount">{{ fiatBetResult }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { CasinoLastBet } from '@st/casino/components/LastBets/types'
import { isNullish } from '@st/utils'
import { useCasinoBet, useFreeSpinBet } from '../../composables'
import freespin from './freespin.png'

const props = withDefaults(
  defineProps<{
    mobile?: boolean
    casinoBet: CasinoLastBet
  }>(),
  {
    mobile: false,
  },
)

const { casinoBet } = toRefs(props)

const {
  categoryIcon,
  categoryName,
  relativeDate,
  formattedRate,
  currencyIcon,
  cryptoBetsAmount,
  fiatBetsAmount,
  betResultClass,
  cryptoAmount,
  fiatBetResult,
  winsAmountInGameCurrency,
} = useCasinoBet(casinoBet)

const {
  isFreespin,
  freeSpinResultClass,
  freeSpinFiatSignBetResult,
  freeSpinResult,
  freeSpinBetsNumber,
  freeSpinFormatedBetsNumber,
} = useFreeSpinBet(casinoBet)
</script>

<style scoped>
.casino-bet {
  cursor: pointer;

  display: flex;
  gap: var(--spacing-300);
  align-items: center;

  padding: var(--spacing-100) var(--spacing-150);

  background-color: var(--background-secondary);
  border-radius: var(--border-radius-100);

  > * {
    flex: 10 1 0;
    min-width: 100px;
  }

  &:hover {
    background-color: var(--background-tertiary);
  }
}

.game {
  display: flex;
  flex-grow: 15;
  gap: var(--spacing-150);
  align-items: center;

  color: var(--text-secondary);
}

.category-icon {
  flex-shrink: 0;
}

.game-name {
  overflow: hidden;

  font: var(--desktop-text-sm-medium);
  color: var(--text-primary);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.game-info {
  flex-shrink: 1;
  min-width: 0;
  text-decoration: none;

  &:hover .game-name {
    color: var(--text-link);
  }
}

.category-name {
  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
}

.bet-id {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);
}

.bet-id-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bet-id-copy {
  flex-shrink: 1;
  min-width: 0;
}

.date {
  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);
}

.crypto-amount {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  font: var(--desktop-text-sm-medium);
  text-align: right;

  &.positive {
    color: var(--text-success);
  }

  &.negative {
    color: var(--text-tertiary);
  }
}

.fiat-amount {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
  text-align: right;
}

.bet-amount,
.bet-payoff {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.bet-rate {
  flex-grow: 5;
  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);
  text-align: right;

  &.positive {
    color: var(--text-success);
  }
}

/* mobile layout */

.casino-bet.mobile {
  background: var(--background-primary);
  border-radius: var(--border-radius-150);
}
</style>
